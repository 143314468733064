.Alert {
  /* position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: flex-end; */
}

.Alert__container {
  position: fixed;
  top: 1rem;
  right: 0;
  background-color: black;
  color: white;
  padding: 1rem;
  margin-right: 1rem;
  width: 200px;
}

.Alert__close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: crimson;
  padding: 0.5rem 1rem;
}
