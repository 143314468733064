.container {
  padding: 10px;
}
.principal-title {
  margin: 0 0 10px;
}
.images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.images-row-container {
  display: flex;
}
.images-row-container img {
  max-width: 350px;
  margin: 3px;
  border-radius: 5px;
}
.text-container {
  padding: 0 10px;
  text-align: justify;
}
.text-special {
  margin: 0 2px;
}
.text-subtitle {
  text-align: center;
  font-size: 1.5rem;
}
