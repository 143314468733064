.form-item {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-content: center;
  max-width: 400px;
}
.form-item label {
  margin-bottom: 5px;
  font-weight: bolder;
  font-size: 0.9em;
}
.form-item input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid darkgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 0.8em;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
}
.form-item input:focus {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #417ae8;
}

::placeholder {
  color: darkgrey;
}
.cart-section {
  display: flex;
  justify-content: center;
}
