.menu-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 100%;
  padding: 0 !important;
}
.link {
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  padding-left: 10px;
  padding-bottom: 5px;
}
.link:hover {
  text-decoration: none;
  background-color: blueviolet;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.line {
  border-top: 1px solid dimgray;
  width: 100%;
  margin: 0 0 5px;
}
