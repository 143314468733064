.container {
  padding: 10px;
}
.principal-title {
  margin: 0 0 10px;
}
.images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}
.images-row-container {
  display: flex;
  align-items: center;
}
.images-row-container a {
  text-decoration: none;
}
.images-row-container img {
  width: 180px;
  height: 140px;
  margin: 0.5rem;
  border-radius: 5px;
}
.images-row-container h4 {
  text-align: center;
  margin: 0.4rem 0 0;
}
.text-container {
  padding: 0 10px;
  margin-left: 100px;
  margin-right: 150px;
  text-align: center;
}
.text-special {
  margin: 0 2px;
  text-align: center;
}
.navbar-container {
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
}
.sp-strong {
  text-decoration: underline;
}
.surgical-title {
  text-align: center;
  color: black;
  text-decoration: underline;
  text-decoration-color: black;
  margin-bottom: 0.5rem;
}
.surgical-subtitle {
  text-align: center;
  margin-top: 0;
}

.surgical-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal {
  align-items: center;
}