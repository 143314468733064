.container {
  padding: 10px;
}
.principal-title {
  margin: 0 0 10px;
}
.images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.images-row-container {
  display: flex;
}
.images-row-container img {
  max-width: 350px;
  margin: 3px;
  border-radius: 5px;
}
.text-container-request {
  padding: 0 10px;
  text-align: justify;
  margin-bottom: 16px;
}
.text-special {
  margin: 0 2px;
}
.form-ck {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 0;

  width: 25%;
  /* max-width: 600px; */
}
.form-ck label {
  margin-bottom: 5px;
  font-weight: bolder;
  font-size: 0.9em;
}
.form-ck input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid darkgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 0.8em;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
}
.form-ck input:focus {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #417ae8;
}

::placeholder {
  color: darkgrey;
}

.form-ck textarea {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid darkgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 0.8em;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
  resize: none;
}
.form-ck textarea:focus {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #417ae8;
}
.main-container-request {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.form-ck h3 {
  margin: 0.1rem 0;
}
.option-payment {
  /* width: 80%; */
  display: flex;
  justify-content: space-evenly;
}
.special-label {
  margin-left: 0.5rem;
}
.special-label small {
  font-size: 0.9rem !important;
  font-weight: bold;
}
.special-text-btn {
  font-size: 0.8rem;
}
.btn-send__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-send__wrapper span {
  margin-right: 0.3rem;
}
