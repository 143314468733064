.container {
  padding: 10px;
}
.principal-title {
  margin: 0 0 10px;
  text-align: center;
}
.images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.images-row-container {
  display: flex;
}
.images-row-container img {
  max-width: 350px;
  margin: 3px;
  border-radius: 5px;
}
.text-container-request {
  text-align: center;
  margin-right: 100px;
  margin-bottom: 16px;
  margin-left: 70px;
}
.text-special {
  margin: 0 2px;
  text-align: center;
  margin-bottom: 16px;
}
.form {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  margin: 0;
  align-self: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  /* max-width: 600px; */
}
.form label {
  margin-bottom: 5px;
  font-weight: bolder;
  font-size: 0.9em;
}
.form input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid darkgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 0.8em;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
}
.form input:focus {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #417ae8;
}

::placeholder {
  color: darkgrey;
}

.form textarea {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid darkgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 0.8em;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
}
.form textarea:focus {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #417ae8;
}
.main-container-request-sample {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
