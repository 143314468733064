.product-container {
  background-color: #f7f7f7;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 3px;
  margin-bottom: 1rem;
}
.form-search {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.form-search input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid darkgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 0.8em;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 5px;
}
.form-search input:focus {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #417ae8;
}
