.store-menu-container {
  display: flex;
  justify-content: center;
}
.table-section-1 {
  width: 70%;
  padding-left: 10px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}
.table-section-2 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 5%;
}
.section-1 {
  background-color: #f7f7f7;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
.section-2 {
  background-color: #f7f7f7;
  border: none;
  width: 100%;
  max-height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
.sub-section {
  display: flex;
}
.section-1 h3 {
  margin: 0 0 0.5rem;
  color: #021f59;
}
.sub-section {
  display: flex;
  justify-content: space-between;
}
